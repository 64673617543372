/* website_skyreachers.css */

/* General Styles */
body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  border-left: 40px solid #FFf5ee; /* light beige */
  border-right: 40px solid #FFf5ee; /* light beige */
}

/* Navbar Styles */
nav.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logo and brand name container */
.navbar .navbar-brand {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
}

/* Style for the Skyreachers link */
.navbar .navbar-brand a {
  color: #000000; /* Black text color */
  text-decoration: none; /* Removes underline from the link */
  display: inline-block; /* Allows vertical alignment */
  vertical-align: middle; /* Aligns text vertically with respect to its container */
  line-height: normal; /* Resets line-height to normal if it's been set elsewhere */
  margin-bottom: -5px; /* Lifts the text up by 5px; adjust as needed */
}

/* Logo image */
.navbar .navbar-brand img {
  height: 80px; /* Adjust as needed */
  margin-right: 10px; /* Space between logo and text */
  vertical-align: middle; /* Aligns logo vertically with respect to its container */
}

/* Navigation links */
.navbar .nav-links {
  display: flex;
  align-items: center;
  gap: 30px;
  /* Additional styles */
}

.navbar .nav-links a {
  font-family: 'Montserrat', sans-serif; /* Change to the desired font-family */
  font-size: 1em; /* Adjust the font size as needed */
  color: #C57B57; /* Adjust to the desired link color */
  text-decoration: none; /* Removes underline from links */
  padding: 0.5em 0; /* Add padding to increase clickable area */
}

/* Auth buttons */
.navbar .auth-buttons a, .navbar .auth-buttons button {
  /* Adjust the styles for your login/sign up buttons to match the desired design */
  background-color: #F18B81; /* Example: a peach color for the button background */
  color: #FFFFFF; /* Text color for the button */
  border: none; /* No border for the button */
  padding: 0.5em 1em; /* Padding inside the button */
  border-radius: 5px; 
  margin-left: 12px;
}

/* Hero Section Styles */
.hero {
  position: relative;
  background-color: #fff; /* Just in case the image fails to load */
}

.hero .hero-image {
  width: 100%;
  height: auto;
  display: block;
}

.hero .hero-content {
  position: absolute;
  top: 20%; /* Adjust as per the design for vertical positioning */
  left: 10%; /* Adjust as per the design for horizontal positioning */
  width: 50%; /* Adjust the width as necessary to match the design */
}

.hero h1 {
  font-size: 2.5em; /* Large text for the main title */
  margin-bottom: 0em;
}

.hero p.hero-subtitle {
  font-size: 1.2em; /* Adjusted size for the subtitle */
  margin-bottom: 1em; /* Space between subtitle paragraphs */
  color: #333; /* Adjust the color to match the design */
}

.hero .scroll-link {
  display: inline-block; /* Allows padding and margin to be applied correctly */
  background-color: #F18B81; /* Adjust the color to match the design */
  color: #FFFFFF;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 1em;
}

.hero .scroll-link:hover {
  background-color: #ffbb8c;
}

/* Additional styles for other sections would follow... */

/* Features Section Styles */
.features {
  text-align: center; /* Center everything in the features section */
  padding: 2em; /* Provide some padding around the content */
  background-color: #fff; /* Set a background color if needed */
}

.features h2 {
  color: #333;
  font-size: 2em; /* Adjust font size as needed */
  margin-bottom: 1em; /* Add space below the title */
}

.features .features-intro {
  font-size: 1.2em; /* Adjust font size as needed */
  max-width: 600px; /* Max width for better readability */
  margin: 0 auto 2em; /* Center the text block and add space below */
}

.features .features-image {
  max-width: 100%; /* Ensure the image is responsive and doesn't overflow */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 0em; /* Add space below the image */
}

/* Feature Item Styles */
.feature-item {
  /* Add styling for feature items here */
}

/* Peer Journeys Section Styles */

.peer-journeys {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Puts maximum space between text and image */
  padding: 2em;
  background-color: #FFF; /* White background or any color as per your design */
}

.peer-journeys .container {
  display: flex;
  align-items: flex-start; /* Align items to start at the top */
  justify-content: space-between; /* This ensures that the text and image are spaced apart */
  padding-left: 5em; /* Space between text and image */

}

.peer-journeys .text-side {
  flex: 1; /* Allows this div to grow and take up the remaining space */
  padding-right: 3em; /* Ensures there's space between the text and image */
}

.peer-journeys .image-side {
  flex: 1; /* Allows this div to grow and take up space */
  /* No need for text-align as it's an image */
}

.peer-journeys .feed-image {
  width: 100%; /* Ensures the image is responsive within its container */
  max-width: 500px; /* Sets a max-width for the image */
  height: auto; /* Maintains aspect ratio */
  margin-left: auto; /* This will push the image to the right */
}

@media (max-width: 768px) {
  .peer-journeys .container {
    flex-direction: column-reverse; /* Stack image on top of text on small screens */
  }

  .peer-journeys .text-side,
  .peer-journeys .image-side {
    flex: none; /* This disables the flex grow and shrink */
    max-width: 100%; /* Allows each section to take full width */
  }

  .peer-journeys .feed-image {
    margin-left: 0; /* Reset left margin for smaller screens */
    margin-bottom: 1em; /* Adds space below the image */
  }
}

/* Generate Journeys Section Styles */
.generate-journeys .container {
  display: flex;
  align-items: flex-start; /* Align items to start at the top */
  justify-content: space-between; /* Spaces the image and text apart */
  padding: 2em;
  background-color: #FFFFFF; /* Soft blue background, adjust the color to match your design */
}

.generate-journeys .image-side {
  flex: 1; /* Allows this div to grow and take up space */
  padding-left: 5em; /* Space between text and image */
  /* No need for text-align as it's an image */
}

.generate-journeys .text-side {
  flex: 1; /* Allows this div to grow and take up the remaining space */
  padding-left: 2em; /* Ensures there's space between the image and text */
}

.generate-journeys .update-image {
  width: 100%; /* Ensures the image is responsive within its container */
  max-width: 500px; /* Sets a max-width for the image */
  height: auto; /* Maintains aspect ratio */
  margin-right: auto; /* This will push the image to the left */
}

@media (max-width: 768px) {
  .generate-journeys .container {
    flex-direction: column; /* Stack text on top of image on small screens */
  }

  .generate-journeys .image-side,
  .generate-journeys .text-side {
    flex: none; /* This disables the flex grow and shrink */
    max-width: 100%; /* Allows each section to take full width */
  }

  .generate-journeys .update-image {
    margin-right: 0; /* Reset right margin for smaller screens */
    margin-bottom: 1em; /* Adds space below the image */
  }
}

/* Add styling for generate journey items here */

/* AI Assistant Section Styles */
.ai-assistant {
  background-color: #FFF; /* Adjust background color if needed */
  padding: 2em;
}

.ai-assistant .container {
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
  justify-content: space-between; /* Place text and image apart */
  padding-left: 5em; /* Space between text and image */
}

.ai-assistant .text-side {
  flex: 1; /* Allows div to grow and take up space */
  padding-right: 2em; /* Space between text and image */
}

.ai-assistant .image-side {
  flex: 1; /* Allows div to grow and take up space */
}

.ai-assistant .ai-assistant-image {
  width: 100%; /* Ensure image is responsive */
  max-width: 500px; /* Max-width for the image */
  height: auto; /* Maintain aspect ratio */
  margin-left: auto; /* Pushes image to the right */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ai-assistant .container {
    flex-direction: column-reverse; /* Stack image on top of text on small screens */
  }

  .ai-assistant .text-side,
  .ai-assistant .image-side {
    flex: none; /* Disable flex grow and shrink */
    max-width: 100%; /* Full width on small screens */
    text-align: center; /* Center text on small screens */
    padding: 0;
    margin-bottom: 1em; /* Space below each section */
  }

  .ai-assistant .ai-assistant-image {
    margin-left: 0; /* Reset left margin for smaller screens */
    margin-bottom: 1em; /* Space below the image */
  }
}
/* Add styling for AI assistant items here */

/* Pricing Section Styles */
.pricing {
  padding: 2em 0; /* Provide some top and bottom padding for the section */
  display: flex; /* Use flexbox to center the content */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  background-color: #FFF; /* Set a background color if desired */
}

.pricing .container {
  width: 100%; /* Ensure the container takes the full width */
  max-width: 1200px; /* Set a maximum width for the container */
  margin: 0 auto; /* Center the container */
}

.pricing-tier {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  width: 100%; /* Full width of the container */
  display: flex; /* Use flexbox for centering the iframe */
  justify-content: center; /* Center the iframe horizontally */
}

iframe {
  max-width: 100%; /* Ensure the iframe is not wider than its container */
  height: 600px; /* Set a fixed height for the iframe */
  border: none; /* Remove the border around the iframe */
}

@media (max-width: 768px) {
  .pricing .container {
    max-width: 100%; /* On smaller screens, the container should take full width */
  }
}

/* Footer Styles */
.footer {
  background-color: #fff5ee; /* The peach background color */
  color: #333; /* Text color */
  text-align: center; /* Center align the text */
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Allows content to wrap for responsiveness */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Maximum width of the footer content */
  margin: auto;
}

.footer-brand {
  flex: 1; /* Takes up space but can shrink */
  text-align: center;
}

.footer-logo {
  width: auto;
  height: 60px; /* Adjust the size of your logo */
  margin-bottom: 1em;
}

.footer-links {
  display: flex;
  justify-content: space-between; /* Distributes space between link columns */
  flex: 2; /* Takes twice as much space as the brand */
  text-align: left; /* Aligns the links to the left */
}

.footer-column {
  display: flex;
  flex-direction: column;
  min-width: 10px; /* Minimum width for each column to avoid squishing */
}

.footer-links a {
  margin: 0.3em 0; /* Vertical space between links */
  color: inherit; /* Footer text color */
  text-decoration: none; /* No underline */
}

.footer-links a:hover {
  text-decoration: underline; /* Underline on hover for better usability */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-brand {
    margin-bottom: 1em; /* Space between logo and links when stacked */
    text-align: center; /* Center align the text for mobile */
  }

  .footer-links {
    flex-direction: column; /* Stack link columns */
    align-items: center; /* Center align the links for mobile */
  }

  .footer-column:not(:last-child) {
    margin-bottom: 1em; /* Space between columns when stacked */
  }

  .footer-links a {
    margin: 0.5em; /* Adjust space around links for smaller screens */
  }
}

/* Responsive adjustments for both Peer Journeys and Generate Journeys sections */
@media (max-width: 767px) {
  .peer-journeys .container,
  .generate-journeys .container {
    flex-direction: column;
    align-items: stretch;
  }

  .peer-journeys .text-side,
  .peer-journeys .image-side,
  .generate-journeys .text-side,
  .generate-journeys .image-side {
    flex: none;
    max-width: 100%;
    text-align: center;
    padding: 0;
    margin-bottom: 1em;
  }

  .peer-journeys .feed-image,
  .generate-journeys .update-image {
    margin: auto;
    display: block;
  }

  .nav-links{
    display:none !important;
  }

  .navbar .auth-buttons a, .navbar .auth-buttons button {
    padding: 0.5em 0.5em; /* Padding inside the button */
  }

  .auth-buttons{
    width:200px;
  }

  .hero .hero-content {
    top: 0; /* Adjust as per the design for vertical positioning */
    left: 0; /* Adjust as per the design for horizontal positioning */
    width: 100%;
    margin:12px; /* Adjust the width as necessary to match the design */
  }

  .navbar .navbar-brand{
    font-size: unset;
  }

  .hero h1 {
    font-size: 2.0em;
    margin-bottom: 0em;
    aspect-ratio: calc(215/89);
  }

  .hero .hero-content{
    position: relative;
    width:100%;
  }

  .hero .hero-image{
    position: absolute;
  }

  .hero p.hero-subtitle {
    font-size: 1rem;
    text-align: center;
  }

  .hero .scroll-link{
    display: none;
  }
  .peer-journeys .container{
    padding-left:0;
  }

  .ai-assistant .container{
    padding-left:0;
  }
}

@media (max-width: 1023px) {
  .nav-links{
    display:none !important;
  }

  .navbar .auth-buttons a, .navbar .auth-buttons button {
    padding: 0.5em 0.5em; /* Padding inside the button */
  }

  .auth-buttons{
    width:100%;
  }

  .hero .hero-content {
    top: 0; /* Adjust as per the design for vertical positioning */
    left: 0; /* Adjust as per the design for horizontal positioning */
    width: 100%;
    margin:12px; /* Adjust the width as necessary to match the design */
  }

  .navbar .navbar-brand{
    font-size: unset;
  }

  .hero .hero-content{
    position: relative;
    width:100%;
  }

  .hero .hero-image{
    position: absolute;
  }

  .hero p.hero-subtitle {
    font-size: 1rem;
    text-align: center;
  }
}


.tier-wrapper{
  display:flex;
  align-items:center;
  flex-direction: column;
  margin-top: 32px;
  justify-content: center;
}

.tier-container{
  display: flex;
  height: 500px;
  overflow-x: scroll;
  max-width: 100vw;
}

.tier-container::-webkit-scrollbar{
  display:none;
}

.tier{
  width: 320px;
  padding: 10px;
  border: 1px solid black;
  margin-left:10px;
  margin-right:10px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  box-shadow: black 1px 1px 0px 0px;
}

.tier-list{
  height: 200px;
}

.tier-button{
      margin-bottom:10px;
      height: 48px;
      background: linear-gradient(to right, var(--center-color-darker), var(--center-color), var(--center-color-lighter), var(--center-color), var(--center-color-darker));
      border-radius: 20px; 
      text-transform: none;
      border: var(--center-color) 1px solid
}

.promo-code{
  display: flex;
  margin-top:12px;
  align-items: center;
}

.promo-code-input{
  background-color: #FAFAFA;
  border-radius: 10px !important;
}

.promo-code-input:focus{
  box-shadow: #F18B81 1px 1px 1px 1px !important;
  border: #F18B81 1px solid !important;
}

.promo-code-button{
  margin-top: 10px;
  background: linear-gradient(to right, var(--center-color-2-darker), var(--center-color-2), var(--center-color-2-lighter), var(--center-color-2), var(--center-color-2-darker));
}

.tier-li:hover{
  background-color: unset !important;
}

.tier-li{
  font-weight: 600;
}

.tier-unready{
  opacity: 50%;
  font-style: italic;
  font-weight:400;
}

.stripe-container{
  width:95%;
  border: 1px solid black;
  box-shadow:black 1px 1px 0 0;
  padding:10px; 
  border-radius:20px;
  max-height: 80vh;
  overflow: scroll;
}

.stripe-container::-webkit-scrollbar{
  display: none;
}

.contact{
  padding-top:32px;
  background-color: white;
  padding-bottom: 32px;
}

.card{
  background: #fafafa !important;
  border: 1px solid #E4E4E4 !important;
  border-radius: 20px;
  padding: 2px;
  width: 60% !important;
}

@media (max-width: 1023px){
  .card{
    width: 95% !important;
  }
}

.send-button {
  /* Adjust the styles for your login/sign up buttons to match the desired design */
  background-color: #F18B81; /* Example: a peach color for the button background */
  color: #FFFFFF; /* Text color for the button */
  border: none; /* No border for the button */
  padding: 0.5em 1em; /* Padding inside the button */
  border-radius: 5px; 
  margin-left: 12px;
  margin-top:12px;
}

@media screen and (max-width:767px) {
  .contact-font{
      font-size: small;
  }
}


.field-error{
  display: flex;
  align-items: center;
  background: #F0DDE2;
  width: fit-content;
  padding: 10px;
  margin-top: 16px;
  border-radius: 10px;
}

.field-success{
  background: #4C7E83;
  color: white;
}
